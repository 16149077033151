'use client';

import { useEffect } from 'react';
import LogRocket from 'logrocket';
import Rollbar from 'rollbar';
import zipy from 'zipyai';
import { H } from '@highlight-run/next/client';

let rollbarInstance: Rollbar | null = null;

export function MonitoringProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
      try {
        // Initialize Zipy
        zipy.init(process.env.NEXT_PUBLIC_ZIPY_SDK_KEY || '');

        // Initialize Rollbar
        rollbarInstance = new Rollbar({
          accessToken: process.env.NEXT_PUBLIC_ROLLBAR_TOKEN,
          captureUncaught: true,
          captureUnhandledRejections: true,
          environment: process.env.NODE_ENV,
          enabled: true,
        });

        // Initialize LogRocket
        LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_ID || '', {
          console: {
            shouldAggregateConsoleErrors: true,
            isEnabled: true,
          },
          network: {
            requestSanitizer: (request) => request,
          },
          release: process.env.NEXT_PUBLIC_APP_VERSION,
          dom: {
            inputSanitizer: true,
            textSanitizer: true,
          }
        });

        // Add detailed error handler
        window.onerror = (message, source, lineno, colno, error) => {
          if (error instanceof Error) {
            logger.error(error, {
              source,
              lineno,
              colno,
              stack: error.stack,
              react: true,
              message: error.message,
              name: error.name
            });
          } else {
            logger.error(String(message), {
              source,
              lineno,
              colno,
              error: error && typeof error === 'object' && 'type' in error ? 'Event' : error,
              react: true
            });
          }
        };

        // Test monitoring is working
        LogRocket.track('App Initialized');
        rollbarInstance.info('Monitoring system initialized');
        H.track('App Initialized');

        // Integrate LogRocket with Rollbar
        LogRocket.getSessionURL((sessionURL) => {
          rollbarInstance?.configure({
            payload: {
              logrocket: sessionURL,
            },
          });
        });

        // Identify user if authenticated
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user?.email) {
          // Identify in LogRocket
          LogRocket.identify(user.email, {
            name: user.name,
            email: user.email,
            role: user.roles?.[0]?.role?.name,
          });

          // Identify in Rollbar
          rollbarInstance.configure({
            payload: {
              person: {
                id: user.email,
                email: user.email,
                username: user.name,
              },
            },
          });

          // Identify in Zipy
          window.zipy?.identify(user.email, {
            name: user.name,
            email: user.email,
            role: user.roles?.[0]?.role?.name,
          });

          // Identify in Highlight.io
          H.identify(user.email, {
            name: user.name,
            email: user.email,
            role: user.roles?.[0]?.role?.name,
          });
        }
      } catch (error) {
        console.error('Error initializing monitoring:', error);
      }
    }
  }, []);

  return children;
}

// Export safe logging functions
export const logger = {
  info: (message: string, data?: any) => {
    if (process.env.NODE_ENV === 'production') {
      rollbarInstance?.info(message, data);
      LogRocket.track(message, data);
      window.zipy?.log(message, data);
      H.track(message, data);
    }
  },
  error: (error: Error | string, data?: any) => {    // Existing Highlight tracking
    if (error instanceof Error) {
      H.consumeError(error);
    }
    if (process.env.NODE_ENV === 'production') {
      rollbarInstance?.error(error, data);
      if (error instanceof Error) {
        LogRocket.captureException(error, data);
        window.zipy?.error(error);
        H.consumeError(error);
        H.track('Error', { ...data, error: error.message, stack: error.stack });
      } else {
        LogRocket.captureMessage(error, data);
        window.zipy?.error(new Error(error));
        const errorObj = new Error(error);
        H.consumeError(errorObj);
        H.track('Error', { ...data, error });
      }
    }
  },
};

// Add type declaration for zipy global
declare global {
  interface Window {
    zipy?: {
      identify: (userId: string, traits?: Record<string, any>) => void;
      log: (message: string, data?: any) => void;
      error: (error: Error) => void;
    };
  }
}

export { rollbarInstance as rollbar, LogRocket };