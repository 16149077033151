"use client";

import React from "react";
import { logger } from "@/services/monitoring";
import { usePathname, useRouter } from "next/navigation";
import { H } from "@highlight-run/next/client";

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
    errorInfo: undefined,
  };

  componentDidMount() {
    logger.info("ErrorBoundary mounted", {
      pathname: window.location.pathname,
      url: window.location.href,
      timestamp: new Date().toISOString(),
    });

    window.addEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
    window.addEventListener("error", this.handleError);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.handleUnhandledRejection
    );
    window.removeEventListener("error", this.handleError);
  }

  private handleUnhandledRejection = (event: PromiseRejectionEvent) => {
    const error =
      event.reason instanceof Error
        ? event.reason
        : new Error(String(event.reason));
    logger.error(error, {
      type: "Unhandled Promise Rejection",
      extra: "Global Error Handler",
      url: window.location.href,
      pathname: window.location.pathname,
      userAgent: window.navigator.userAgent,
      timestamp: new Date().toISOString(),
      state: JSON.stringify(this.state, null, 2),
    });

    this.setState({ hasError: true, error });
  };

  private handleError = (event: ErrorEvent) => {
    logger.error(event.error, {
      type: "Global Error",
      extra: "Global Error Handler",
      url: window.location.href,
      pathname: window.location.pathname,
      userAgent: window.navigator.userAgent,
      timestamp: new Date().toISOString(),
      state: JSON.stringify(this.state, null, 2),
    });

    this.setState({ hasError: true, error: event.error });
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const isError130 = error.message.includes("Minified React error #130");

    logger.error(error, {
      componentStack: errorInfo.componentStack,
      name: error.name,
      message: error.message,
      stack: error.stack,
      isReactError130: isError130,
      extra: isError130
        ? "React Error #130 - Undefined Component"
        : "React Error Boundary Catch",
      url: window.location.href,
      pathname: window.location.pathname,
      loadedChunks: Object.keys((window.__NEXT_DATA__ as any)?.chunks || {}),
      dynamicImports: window.__NEXT_DATA__?.dynamicIds,
      buildId: window.__NEXT_DATA__?.buildId,
    });

    // Existing Highlight tracking
    H.consumeError(error);
    H.track("React Error", {
      // ... existing error context ...
    });

    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  private handleReset = () => {
    logger.info("Error boundary reset attempted", {
      pathname: window.location.pathname,
      previousError: this.state.error?.message,
    });
    this.setState({ hasError: false, error: undefined, errorInfo: undefined });
  };

  public render() {
    if (this.state.hasError) {
      const isError130 = this.state.error?.message.includes(
        "Minified React error #130"
      );

      return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
          <h2 className="text-2xl font-bold mb-4">
            {isError130 ? "Component Loading Error" : "Something went wrong"}
          </h2>

          {process.env.NODE_ENV === "development" && (
            <pre className="mb-4 p-4 bg-red-50 text-red-500 rounded overflow-auto max-w-full">
              <p>
                <strong>Error:</strong> {this.state.error?.message}
              </p>
              <p>
                <strong>Component Stack:</strong>{" "}
                {this.state.errorInfo?.componentStack}
              </p>
            </pre>
          )}

          <div className="flex gap-4">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => window.location.reload()}
            >
              Reload page
            </button>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={() => window.history.back()}
            >
              Go back
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// Higher order component to catch async errors in event handlers
export function withErrorBoundary<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  return function WithErrorBoundaryWrapper(props: P) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
}

export default ErrorBoundary;
